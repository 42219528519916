.page-login {
    background-image: url(~/files-img/backs/bg-mperine.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

    .section {
        margin-top: 35%;

        .form {
            padding: 10px;
            background: rgba(255, 255, 255, 0.22);

            label {
                color: $white;
            }

        }

        .link-forgot {
            color: #ffffff;
            margin-left: 6px;
        }
    }

    .section-reset-passwoord {
        margin-top: 15%;

        .form {
            padding: 10px;
            background: rgba(255, 255, 255, 0.22);

            label {
                color: $white;
            }

        }
    }


}
