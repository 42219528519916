// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import "common/variables";
@import "common/mixins";
@import "common/sizes";
@import "layouts/globals";

// Bootstrap
@import '~bootstrap/scss/bootstrap';


// Partials
@import "partials/header";
@import "partials/socials";
@import "partials/sidemenu";

// Pages
@import "pages/home";
@import "pages/register-fan";
@import "pages/login";
@import "pages/home-fan";
@import "pages/exclusive";
@import "pages/home-reporter";
@import "pages/competition-terms";
