.page-exclusive {

    .top {
        background: rgba(255, 255, 255, 0.12);
        color: #fff;

        .icon {
            position: absolute;
            width: 80px;
            margin-top: -10px;
        }

        h1 {
            font-size: 20px;
            margin: 10px 0px 2px 0px;
        }

        h2 {
            font-size: 15px;
        }
    }

    .item-category {
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 15px;

        h3 {
            text-align: center;
            font-size: 14px;
            margin: 0px 0px 8px 0px;
        }
    }

    .item-img {
        background: #fff;
        border-radius: 20px;
        margin-bottom: 15px;

        h3 {
            text-align: center;
            font-size: 16px;
            margin: 5px 0px 0px 0px;
            padding-top: 5px;
        }

        img {
            border-radius: 10px;
            min-height: 250px;
            max-height: 250px;
            width: 100%;
        }

        .download {
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: 0px;
            bottom: 32px;
            right: 32px;
            padding: 5px;
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.14);
        }

        .download:hover {
            transform: scale(1.2);
        }
    }
}

.modal-exclusive {
    .modal-content {
        border-radius: 0px;

        .modal-header {
            border-bottom: 0px;
            padding: 20px 30px 0px 30px;

            h1 {
                text-transform: uppercase;
                font-size: 22px;
            }
        }

        .modal-body {
            padding: 0px 30px 0px 30px;

            textarea {
                border: 1px solid #323232 !important;
            }

            .btn-custom {
                background: #AF947E;
                color: white;
                font-weight: bold;
                border: 2px solid #AF947E;
                font-size: 20px;
                padding: 5px 20px;
            }
        }
    }

}
