.page-competition-terms {
    background-image: url(~/files-img/backs/competition.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

    p {
        line-height: 1.3em !important;
    }

    .form {
        padding: 10px;
        background: rgba(44, 44, 44, 0.75);

        a {
            color: $white;
        }
    }

    .modal-body {

        .text-light {
            color: #000 !important;
        }

    }
    
}