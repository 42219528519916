.page-register-fan {
    background-image: url(~/files-img/backs/bg-mperine.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 100vh;

    p {
        line-height: 1.3em !important;
    }

    .form {
        padding: 10px;
        background: rgba(255, 255, 255, 0.15);

        label {
            color: $white;
        }

    }

}
