@include font-face('VAG-Rounded-Bold', '~/fonts/VAG-Rounded-Bold', null, null, otf);
@include font-face('VAG-Rounded-Light', '~/fonts/VAGRounded-Light', null, null, otf);

body {
    font-family: 'VAG-Rounded-Bold' !important;
    letter-spacing: 0.5px;
}

.btn-default {
    font-family: 'VAG-Rounded-Light';
    background: $white;
    color: $black;
    padding: 3px 15px;
    min-width: 100px;
    border: 0px;
}

.btn-default:hover {
    text-decoration: none;
}

.form-control {
    background: rgba(255, 255, 255, 0.45) !important;
    border: 0px !important;
    border-radius: 0px !important;
}

.text-light {
    font-family: 'VAG-Rounded-Light';
}
